import * as Moment from 'dayjs';

const formateDate = (date, format) => {
  if (!date) {
    return '--';
  } else {
    return Moment(date).format(format);
  }
};

const filterRender = (val) => {
  if (!val && val !== 0) {
    return '--';
  }

  return val;
};

export function setDetail () {
  const detailTableCols = [
    {
      title: '系统序列号',
      field: 'systemSerialNumber',
      formatter: ({ row }) => {
        return filterRender(row.systemSerialNumber);
      },
    },
    {
      title: '商品编码',
      field: 'goodsCode',
      formatter: ({ row }) => {
        return filterRender(row.goodsCode);
      },
    },
    {
      title: '商品名称',
      field: 'goodsName',
      formatter: ({ row }) => {
        return filterRender(row.goodsName);
      },
    },
    {
      title: '规格',
      field: 'specification',
      formatter: ({ row }) => {
        return filterRender(row.specification);
      },
    },
    {
      title: '型号',
      field: 'model',
      formatter: ({ row }) => {
        return filterRender(row.model);
      },
    },
    {
      title: '品牌名称',
      field: 'brandName',
      formatter: ({ row }) => {
        return filterRender(row.brandName);
      },
    },
    {
      title: '生产商',
      field: 'producer',
      formatter: ({ row }) => {
        return filterRender(row.producer);
      },
    },
    {
      title: '厂家编码',
      field: 'factoryCode',
      formatter: ({ row }) => {
        return filterRender(row.factoryCode);
      },
    },
    {
      title: '生产日期',
      field: 'productionDate',
      formatter: ({ row }) => {
        return formateDate(row.productionDate, 'YYYY-MM-DD');
      },
    },
    {
      title: '失效日期',
      field: 'expiryDate',
      formatter: ({ row }) => {
        return formateDate(row.expiryDate, 'YYYY-MM-DD');
      },
    },
    {
      title: '入库批次',
      field: 'warehousingBatch',
      formatter: ({ row }) => {
        return filterRender(row.warehousingBatch);
      },
    },
    {
      title: '是否医疗件',
      field: 'isMedichair',
      formatter: ({ row }) => {
        return row.isMedichair === 1 ? '是' : row.isMedichair === 2 ? '否' : '';
      },
    },
    {
      title: '注册证号',
      field: 'registrationNumber',
      formatter: ({ row }) => {
        return filterRender(row.registrationNumber);
      },
    },
    {
      title: '计量单位',
      field: 'unitOfMsment',
      formatter: ({ row }) => {
        return filterRender(row.unitOfMsment);
      },
    },
    {
      title: '采购单价',
      field: 'purchPrice',
      formatter: ({ row }) => {
        return filterRender(row.purchPrice);
      },
    },
  ];

  return detailTableCols;
}
